import { Button } from '@progress/kendo-react-buttons';
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { Input } from '@progress/kendo-react-inputs';
import { Error, Label } from '@progress/kendo-react-labels';
import React, { useState } from 'react';
import axios from "axios";
import { useHistory } from 'react-router';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { config } from './config';

const Login = () => {

    const history = useHistory();

    const [loggingIn, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [visibleDialog, setVisibleDialog] = useState(false);

    const login = async (username, password) => {
        let loginPromise = await axios
            .post(config.apiUrl + "login",
                {
                    username: username,
                    password: password
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                setLoading(false);
                return setError("Login Failed.");
            });
        
        if (loginPromise) {
            await localStorage.removeItem("currentUser");
            await localStorage.setItem('user', JSON.stringify(loginPromise.data.data[0]));
            history.push("/home");
        };

        setLoading(false);
    };

    const toggleDialog = () => {
        setVisibleDialog(!visibleDialog);
    };

    const handleSubmit = (e) => {
        setLoading(true);
        login(e.username, e.password);
    };

    const requiredValidator = (value) => (value !== "" ? "" : "Please enter a valid value.");

    const RequiredInput = (fieldRenderProps) => {
        const { validationMessage, visited, ...others } = fieldRenderProps;
        return (
            <div>
                <Input {...others} />
                {
                    visited && validationMessage &&
                    (<Error>{validationMessage}</Error>)
                }
            </div>
        );
    };

    return (
        <div className="background-color">
            {/*<img className="background-image position-absolute" src={require("../assets/images/image.png").default} alt="background" />*/}
            <img className="logo position-absolute" height="50" width="50" src={require("../assets/images/logo.png").default} alt="logo" />
            <div className="position-absolute h-100 end-0 align-items-center d-lg-flex d-none">
                <div className="me-4 text-white">
                    <h3>REPLENOGRAM</h3>
                    <p>Automated Replenishment</p>
                </div>
            </div>
            <div className="position-absolute h-100 end-0 d-flex align-items-start mt-4 d-lg-none d-flex ">
                <div className="me-4 text-white">
                    <h3>REPLENOGRAM</h3>
                    <p>Automated Replenishment</p>
                </div>
            </div>
            <div className="align-items-center d-flex justify-content-center position-relative w-100 login-main-container">
                <div className="shadow-lg login-container bg-white">
                    <div className="justify-content-center text-center row mb-2 h-100 align-items-center">
                        <Form
                            initialValues={{
                                username: "",
                                password: ""
                            }}
                            onSubmit={handleSubmit}
                            render={(formRenderProps) => (
                                <FormElement style={{ maxWidth: 350 }}>
                                    {error &&
                                        (<div className={'k-messagebox k-messagebox-error'}>{error}</div>)
                                    }
                                    <h3 className="text-center">SIGN IN</h3>
                                    <div className="d-inline-flex line" />
                                    <div className="k-form-field mb-2 mt-5">
                                        <Label editorId={"username"}>Username</Label>
                                        <Field id={"username"} name="username" type="text" component={RequiredInput} validator={requiredValidator} autoComplete="Off" />
                                    </div>
                                    <div className="k-form-field mb-4">
                                        <Label editorId={"password"}>Password</Label>
                                        <Field id={"password"} name="password" type="password" component={RequiredInput} validator={requiredValidator} autoComplete="Off" />
                                    </div>
                                    <div className="row">
                                        <div className="col-12 d-grid gap-2">
                                            <Button iconClass={loggingIn ? "fa fa-spin fa-spinner float-right" : "fa fa-lg fa fa-sign-in float-right"}
                                                type="submit" primary={true}
                                                className="px-4 btn-block mt-15">LOGIN
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <span className="mt-4">
                                            <h6>Don't have an account? <a href="/#" className="text-decoration-none" onClick={toggleDialog}>Sign Up</a></h6>
                                        </span>
                                    </div>
                                </FormElement>
                            )}
                        />
                    </div>
                </div>
            </div>

            {visibleDialog && (
                <Dialog title={"Sign Up"} onClose={toggleDialog}>
                    <div className="mt-3 m-5">
                        <h5>For sign up instructions and other details,</h5>
                        <h5>Please contact <a href="mailto:info@synqware.com">info@synqware.com</a></h5>
                    </div>
                    <DialogActionsBar layout="end">
                        <Button className="k-button" onClick={toggleDialog}>Close</Button>
                    </DialogActionsBar>
                </Dialog>
            )}

        </div>
    );
};

export default Login;