import { Button, ButtonGroup } from '@progress/kendo-react-buttons';
import { Grid, GridColumn, GridToolbar, } from "@progress/kendo-react-grid";
import '@progress/kendo-theme-material/dist/all.css';
import axios from "axios";
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState, useRef } from "react";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { process } from "@progress/kendo-data-query";
import { useHistory } from "react-router-dom";
import { ColumnMenu, ColumnMenuCheckboxFilter } from "./ColumnMenu";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { Input } from '@progress/kendo-react-inputs';
import { config } from './config';

const Home = () => {

    const initialDataState = {
        sort: [{ field: "order_date", dir: "asc" }],
        skip: 0,
        take: 25,
    };

    const history = useHistory();

    const _export = useRef(null);
    const [records, setRecords] = useState();
    const [isLoading, setLoading] = useState(true);
    const [dataState, setDataState] = useState(initialDataState);
    const [keyword, setKeyword] = useState("");

    useEffect(() => {
        checkLogin();

        // eslint-disable-next-line
    }, []);

    const excelExport = () => {
        if (_export.current !== null) {
            _export.current.save();
        };
    };

    const handleSearchSubmit = () => {
        let data = process(records, {
            filter: {
                logic: "or", filters: [
                    { field: "customer", operator: "contains", value: keyword },
                    { field: "xml_data.Order.billing_address.city", operator: "contains", value: keyword },
                    { field: "xml_data.Order.billing_address.state", operator: "contains", value: keyword },
                    { field: "repCode", operator: "contains", value: keyword }
                ]
            }
        });
        setDataState(data);
    };

    const handleRefresh = () => {
        setKeyword("");
        let data = process(records, initialDataState);
        setDataState(data);
    };

    const handleKeywordChange = (e) => {
        setKeyword(e.target.value);
    };

    const onRowClick = (e) => {
        history.push("order/" + e.dataItem.id + "/Details");
    };

    const checkLogin = async () => {
        let userItem = await localStorage.getItem('user');
        let user = JSON.parse(userItem);
        if (user) {
            getRecords(user);
        } else {
            history.push("/");
        };
    };

    const getRecords = async (credentials) => {
        const recordsPromise = await axios
            .get(config.apiUrl + "getSuggestedOrders", {
                headers: {
                    "X-Vendor": credentials.vendor
                }
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => console.log(err));
        setRecords(recordsPromise.data.data);
        setLoading(false);
    };

    if (isLoading) {
        return (
            <Loader
                className="Loading"
                type="Circles"
                color="#0189A9"
                height="100"
                width="100"
            />
        );
    }

    return (
        <div className="row">
            <div className="col-12">
                <div className="grid-wrapper">
                    <ExcelExport data={records} ref={_export} fileName="Suggested_Orders.xlsx">
                        <Grid
                            data={process(records, dataState)}
                            {...dataState}
                            onDataStateChange={(e) => {
                                setDataState(e.dataState);
                            }}
                            total={records.length}
                            resizable={true}
                            sortable={true}
                            pageable={true}
                            onRowClick={onRowClick}
                        >
                            <GridToolbar>
                                <div className="row w-100 align-items-center">
                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <div className="row">
                                            <div className="col-12 col-sm-6 col-md-6 col-lg-5">
                                                <Input className="w-100" type={'text'} value={keyword} onChange={handleKeywordChange} label={'Customer/Rep/Location'} />
                                            </div>
                                            <div className="col-12 col-sm-6 col-md-6 col-lg-7 align-self-center d-flex">
                                                <ButtonGroup>
                                                    <Button icon="search" type={'submit'} onClick={handleSearchSubmit} primary={true}  >
                                                        Find
                                                    </Button>
                                                    <Button icon="refresh" type={'button'} onClick={handleRefresh} className={'k-button'} >
                                                        Refresh
                                                    </Button>
                                                </ButtonGroup>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 justify-content-end d-flex">
                                        <Button title="Export Excel" className="k-button k-primary" onClick={excelExport} >
                                            Export to Excel
                                        </Button>
                                    </div>
                                </div>
                            </GridToolbar>
                            <GridColumn field="customer" title="Customer" filter="text" width="250" columnMenu={(e) => ColumnMenuCheckboxFilter(e, records)} />
                            <GridColumn field="xml_data.Order.billing_address.city" title="City" filter="text" width="200" columnMenu={(e) => ColumnMenuCheckboxFilter(e, records)} />
                            <GridColumn field="xml_data.Order.billing_address.state" title="State" filter="text" width="200" columnMenu={(e) => ColumnMenuCheckboxFilter(e, records)} />
                            <GridColumn field="total" title="Total" filter="numeric" width="150" columnMenu={ColumnMenu} />
                            <GridColumn field="repCode" title="Rep" filter="text" width="150" columnMenu={ColumnMenu} />
                            <GridColumn field="order_date" title="Last Order date" format="{0:d}" width="250" />
                        </Grid>
                    </ExcelExport>
                </div>
            </div>
            <div>
                <div className="ml-auto float-end">&copy; {new Date().getFullYear()} Bizperanto | All Rights Reserved</div>
            </div>
        </div>
    );
};

export default Home;
