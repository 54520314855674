import { Button } from '@progress/kendo-react-buttons';
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { Input, NumericTextBox, Switch } from "@progress/kendo-react-inputs";
import { Card, CardBody, CardHeader, CardTitle } from '@progress/kendo-react-layout';
import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import axios from 'axios';
import Loader from "react-loader-spinner";
import { config } from './config';

const Settings = () => {

    const history = useHistory();

    const [settingDetails, setSettingDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [notificationData, setNotificationData] = useState(null);
    const [notificationVisible, setNotificationVisible] = useState(false);

    useEffect(() => {
        getSettingDetails();

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (notificationVisible === true) {
            setTimeout(() => {
                setNotificationVisible(false);
                setNotificationData(null);
            },3000)
        };

         // eslint-disable-next-line
    }, [notificationVisible]);

    const getSettingDetails = async () => {
        let user = await JSON.parse(localStorage.getItem("user"));
        const settingsPromise = await axios
            .get(config.apiUrl + "getSettingsDetails/" + user.id)
            .then((response) => {
                return response.data;
            })
            .catch((err) => console.log(err));
        setSettingDetails(settingsPromise.data);
        setIsLoading(false);
    };

    const updateProfile = async (data) => {
        let user = await JSON.parse(localStorage.getItem("user"));
        await axios
            .post(config.apiUrl + "editSettings/" + user.id, data,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
            .then((response) => {
                if (response.data.data === "success") {
                    setNotificationData({ style: "success", message: "Successfully updated settings." });
                    setNotificationVisible(true);
                };
                return;
            })
            .catch((err) => {
                setNotificationData({ style: "error", message: "Something went wrong, Please try again later." });
                setNotificationVisible(true);
            });
    };

    const backToHome = () => {
        history.push('/home');
    };

    const hideNotification = () => {
        setNotificationVisible(false);
        setNotificationData(null);
    };

    const handleSubmit = (e) => {
        if (e.currentPassword && e.currentPassword !== "") {
            if (!e.newPassword || !e.confirmNewPassword) {
                setNotificationData({ style: "error", message: "Please enter both new password and confirm new password." });
                setNotificationVisible(true);
            } else {
                if (e.newPassword !== e.confirmNewPassword) {
                    setNotificationData({ style: "error", message: "New password and confirm new password both should be same." });
                    setNotificationVisible(true);
                } else {
                    return updateProfile(e);
                };
            };
        } else {
            return updateProfile(e);
        };
    };

    if (isLoading) {
        return (
            <Loader
                className="Loading"
                type="Circles"
                color="#0189A9"
                height="100"
                width="100"
            />
        );
    }

    return (
        <div className="row">
            <div className="col-12 mt-2 position-relative">
                <Form
                    initialValues={settingDetails}
                    key={JSON.stringify(settingDetails)}
                    onSubmitClick={(e) => handleSubmit(e.values)}
                    render={(formRenderProps) => (

                        <FormElement>
                            <Card>
                                <CardHeader>
                                    <CardTitle className="float-start">Change Settings</CardTitle>
                                    <Button type={'submit'} primary={true} className="float-end">Save</Button>
                                    <Button className="float-end" onClick={backToHome}>Cancel</Button>
                                </CardHeader>
                                <CardBody>
                                    <div className="row">
                                        <div className="row mb-4">
                                            <div className="row mb-1">
                                                <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                                                    <div className="k-form-field">
                                                        <Field name="vendorCode" label="Vendor Code" component={Input} autoComplete="Off" disabled />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                                                    <div className="k-form-field">
                                                        <Field name="name" label="Name" component={Input} autoComplete="Off" />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                                                    <div className="k-form-field">
                                                        <Field name="email" label="Email" component={Input} autoComplete="Off" />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                                                    <div className="k-form-field ">
                                                        <Field name="currentPassword" type='password' label="Current Password (only if changing password)." component={Input} autoComplete="Off" />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                                                    <div className="k-form-field ">
                                                        <Field name="newPassword" type='password' label="New Password" component={Input} autoComplete="Off" />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                                                    <div className="k-form-field ">
                                                        <Field name="confirmNewPassword" type='password' label="Confirm New Password" component={Input} autoComplete="Off" />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                                                    <div className="k-form-field ">
                                                        <Field name="replencastFrequency" label="Replencast Frequency (days)" component={NumericTextBox} autoComplete="Off" />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-12 col-md-6 col-lg-4 align-self-center">
                                                    <div className="k-form-field d-flex gap-4 ">
                                                        <h6 className="m-0">Auto Replencast (to retailers)</h6>
                                                        <Field name="autoReplencast" label="Auto Replencast (to retailers)" component={Switch} autoComplete="Off" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mt-3 mb-2">
                                        <div className="col-12">
                                            <Button type={'submit'} primary={true} className="float-end">Save</Button>
                                            <Button className="float-end" onClick={backToHome}>Cancel</Button>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </FormElement>
                    )}
                />
                {/*  }*/}
            </div>
            <NotificationGroup
                style={{
                    right: 0,
                    bottom: 0,
                    alignItems: "flex-start",
                    flexWrap: "wrap-reverse",
                }}
            >
                {notificationVisible && notificationData &&
                    <Notification type={{ style: notificationData.style, icon: true }} closable={true} onClose={hideNotification}>
                        <span>{notificationData.message}</span>
                    </Notification>
                }
            </NotificationGroup>

        </div>
    );
};

export default Settings;