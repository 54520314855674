import * as React from "react";
import { Route, Switch } from "react-router-dom";
import AppHeader from "./AppHeader";
import ConfirmOrder from "./ConfirmOrder";
import EmptyCart from "./EmptyCart";
import Home from "./Home";
import OrderDetails from "./OrderDetails";
import PlaceOrder from "./PlaceOrder";
import ProductList from "./ProductList";
import Settings from "./Settings";

const Layout = () => {
    return (
        <div className="container-fluid mainContainer">
            <AppHeader />
            <Switch>
                <Route path="/home" component={Home} />
                <Route path="/emptyCart" component={EmptyCart} />
                <Route path="/order/:id/Details" component={OrderDetails} />
                <Route path="/order/:id/checkout" component={PlaceOrder} />
                <Route path="/order/:id/addProducts" component={ProductList} />
                <Route path="/order/:id/confirm" component={ConfirmOrder} />
                <Route path="/settings" component={Settings} />
            </Switch>
        </div>
    );
};

export default Layout;