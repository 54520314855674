import { Button } from '@progress/kendo-react-buttons';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Badge, BadgeContainer } from '@progress/kendo-react-indicators';
import { Link } from 'react-router-dom';
import CountContext from './context';

const AppHeader = () => {

    const history = useHistory();

    const [name, setName] = useState('');
    const {count, setCount} = useContext(CountContext);

    useEffect(() => {
        getUserDetails();
        getCartDetails();

        // eslint-disable-next-line
    }, []);
    
    const getUserDetails = async () => {
        let userItem = await JSON.parse(localStorage.getItem('user'));
        setName(userItem.login);
    };

    const getCartDetails = async () => {
        let getSession = await localStorage.getItem("currentUser");

        if (getSession != null && getSession !== "") {
            let details = JSON.parse(getSession);
            setCount(details.items.length);
        } else {
            setCount(0);
        };
    };

    const goToCart = async () => {
        let getSession = await localStorage.getItem("currentUser");

        if (getSession != null && getSession !== "") {
            let details = JSON.parse(getSession);
            history.push("/order/" + details.id + "/Details");
        } else {
            history.push("/emptyCart");
        };
    };

    const logout = async (e) =>  {
        await localStorage.removeItem('user');
        await localStorage.removeItem('currentUser');
        history.push("/");
    };

    const onSettingClick = () => {
        history.push("/settings")
    };

    return (
        <div className="row position-fixed start-0 top-0 end-0 bg-white appHeader">
            <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-1 border-bottom backgroundColor">

                <Link to="/home" className="d-flex align-items-center col-md-1 mb-2 mb-md-0 text-dark text-decoration-none ps-2">
                        <img src={require("../assets/images/logo.png").default} height="50" alt="logo" />
                    </Link>
                
                <div className="align-items-center col-md-8 d-flex gap-3 justify-content-end text-end pe-2">
                    <h6 className="m-0">{name},</h6>
                    <div className="float-end mt-2">
                        <Button className="k-button k-button-clear" onClick={goToCart}>
                            <BadgeContainer>
                                <span className="k-icon k-i-shopping-cart badge-cart" />
                                <Badge>{count}</Badge>
                            </BadgeContainer>
                        </Button>
                    </div>
                    <Button icon="logout" onClick={logout}></Button>
                    <Button icon="gear" primary={true} onClick={onSettingClick}></Button>
                </div>
            </div>
        </div>
    );
};

export default AppHeader;