import { Button } from '@progress/kendo-react-buttons';
import { Dialog } from '@progress/kendo-react-dialogs';
import React, { useEffect, useState } from 'react';
import { Form, FormElement } from '@progress/kendo-react-form';

const ProductDetail = (props) => {

    const [productDetail, setProductDetail] = useState(null);

    useEffect(() => {
        setProductDetail(props.productDetail);

        // eslint-disable-next-line
    }, []);

    const hideDialog = () => {
        props.setDialogVisible(false);
    };

    const addToCart = () => {
        props.addToCart(props.productDetail);
    };

    return (
        <Dialog className="large-window" title={"Product Detail"} onClose={hideDialog} width="60%">
            {productDetail &&
                <Form
                    initialValues={productDetail}
                    key={JSON.stringify(productDetail)}
                    onSubmitClick={(e) => console.log(e.values)}
                    render={(formRenderProps) => (
                        <FormElement>
                            <div className="card">
                                <div className="card-body">
                                    <div className="row mb-3">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 d-flex justify-content-center mb-2">
                                            <img className="mt-3 imageFit" alt="product" src="https://cdn.shopify.com/s/files/1/0350/4727/6589/products/0-122361.jpg?v=1593192261" height="200px" />
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
                                            <h5>{productDetail.desc}</h5>
                                            <div className="d-flex align-items-baseline mt-2">
                                                <h5 className="text-black-50">Price  :</h5>
                                                <h5 className="fw-bold d-flex justify-content-end fw-bold">${productDetail.price}</h5>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-3 d-flex justify-content-between align-items-center">
                                                    <Button type="button" icon="minus" primary={true}></Button>
                                                    {"1"}
                                                    <Button type="button" icon="plus" primary={true}></Button>
                                                </div>
                                            </div>
                                            <div className="row mt-4">
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-7">
                                                    <div className="row">
                                                        <h6 className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-6"><strong>Vendor</strong></h6>
                                                        <h6 className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-6">{productDetail.vendor ? productDetail.vendor : "-"}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-7">
                                                    <div className="row">
                                                        <h6 className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-6"><strong>Category</strong></h6>
                                                        <h6 className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-6">{productDetail.category ? productDetail.category : "-"}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-7">
                                                    <div className="row">
                                                        <h6 className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-6"><strong>Brand</strong></h6>
                                                        <h6 className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-6">{productDetail.brand ? productDetail.brand : "-"}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-7">
                                                    <div className="row">
                                                        <h6 className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-6"><strong>Size</strong></h6>
                                                        <h6 className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-6">{productDetail.size ? productDetail.size : "-"}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-7">
                                                    <div className="row">
                                                        <h6 className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-6"><strong>Color</strong></h6>
                                                        <h6 className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-6">{productDetail.color ? productDetail.color : "-"}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-7">
                                                    <div className="row">
                                                        <h6 className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-6"><strong>Weight</strong></h6>
                                                        <h6 className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-6">{productDetail.weight ? productDetail.weight : "-"}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <Button type={'submit'} icon={"k-icon k-i-shopping-cart"} primary={true} className="float-end ps-3 pe-3 mt-3 me-3" onClick={addToCart}>ADD TO CART</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </FormElement>
                    )}
                />}
        </Dialog>
    );
};

export default ProductDetail;
