import { useEffect, useState } from "react";
import axios from 'axios';
import Loader from 'react-loader-spinner';
import { Card, CardBody, CardFooter } from '@progress/kendo-react-layout';
import { Button } from '@progress/kendo-react-buttons';
import { ComboBox } from "@progress/kendo-react-dropdowns";
import ProductDetail from "./ProductDetail";
import { useHistory } from "react-router-dom";
import { config } from "./config";
import moment from 'moment';

const ProductList = (props) => {

    const history = useHistory();

    const [products, setProducts] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [filteredData, setFilteredData] = useState([]);
    const [value, setValue] = useState("");
    const [searchLoading, setSearchLoading] = useState(false);
    const [productDetailDialogVisible, setProductDetailDialogVisible] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [customerId, setCustomerId] = useState(null);
    
    useEffect(() => {
        const { match: { params } } = props;

        setCustomerId(params.id);
        getAllProducts("");

        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        getAllProducts(value);

        // eslint-disable-next-line
    }, [value]);

    const handleSearchChange = (event) => {
        const value = event.target.value;
        setValue(value);
    };

    const handleFilterChange = async (e) => {
        setSearchLoading(true);
        await axios
            .post(config.apiUrl + "getAllItemCode", { itemCode: e.filter.value })
                .then((response) => {
                    setFilteredData(response.data.data);
                    return response.data;
                })
            .catch((err) => console.log(err));
        setSearchLoading(false);
    };

    const getAllProducts = async (text) => {
        let user = await JSON.parse(localStorage.getItem("user"));
        const productsPromise = await axios
            .post(config.apiUrl + "Products", { text: text }, {
                headers: {
                    "X-Vendor": user.vendor
                }
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => console.log(err));
        setProducts(productsPromise.data.data);
        setLoading(false);
    };

    const onProductSelected = async (detail) => {
        setProductDetailDialogVisible(true);
        setSelectedProduct(detail);
    };

    const addToCart = async (item) => {
        let newItem = {
            line_number: null, sku: item.item, description: item.desc, price: item.price, qty: 1, min: item.min, units: item.units, last_ordered: moment().format('MM/DD/YYYY')};
        let getItem = await localStorage.getItem("currentUser");

        if (getItem !== null) {
            let data = JSON.parse(getItem);
            data.items = [...data.items, newItem];
            await localStorage.setItem("currentUser", JSON.stringify(data));
            history.push("/order/" + customerId +"/details");
        } else {
            history.push("/home");
        };
    };

    if (isLoading) {
        return (
            <Loader
                className="Loading"
                type="Circles"
                color="#0189A9"
                height="100"
                width="100"
            />
        );
    }
    
    return (
        <div className="row mx-4 mb-5">
            <div className="mb-3 d-flex justify-content-end">
                <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
                    <ComboBox iconClassName="k-icon k-i-search" name="search" label="SKU / Description" autoComplete="Off" data={filteredData} value={value} onChange={handleSearchChange} allowCustom={true} filterable={true} onFilterChange={handleFilterChange} loading={searchLoading} />
                </div>
            </div>
            <div className="productListWrap">
                {products &&
                    products.map((item, index) => {
                        return (
                            <div key={index}>
                                <Card className="productCard">
                                    <CardBody>
                                        <div className="row" onClick={() => onProductSelected(item)}>
                                            {item.is_bestseller && <h6 className="fw-bold primaryColor">BEST SELLER</h6>}
                                            {item.is_special && <h6 className="fw-bold secondaryColor">SPECIAL</h6>}
                                            {!item.is_bestseller && !item.is_special && <div className="mb-3" />}
                                            <img className="mt-1 imageFit" src="https://cdn.shopify.com/s/files/1/0350/4727/6589/products/0-122361.jpg?v=1593192261" alt="product" height="110px" />
                                            <p className="mt-3 text-center">{item.desc.substring(0, 45)}{item.desc.length > 45 && "..." }</p>
                                            <div className="d-flex justify-content-center align-items-baseline mt-3">
                                                <h6 className="text-black-50">Price  :</h6>
                                                <h6 className="fw-bold d-flex justify-content-end fw-bold">${item.price}</h6>
                                            </div>
                                        </div>
                                    </CardBody>
                                    <CardFooter className="p-0">
                                        <div className="row">
                                            <Button type={'submit'} icon={"k-icon k-i-shopping-cart"} primary={true} className="float-end p-2" onClick={() => addToCart(item)}>ADD TO CARt</Button>
                                        </div>
                                    </CardFooter>
                                </Card>
                            </div>
                        );
                    })
                }
            </div>

            {productDetailDialogVisible && selectedProduct &&
                <ProductDetail setDialogVisible={setProductDetailDialogVisible} productDetail={selectedProduct} addToCart={addToCart} />
            }
        </div>
    );
};

export default ProductList;