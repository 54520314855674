import './App.css';
import '@progress/kendo-theme-material/dist/all.css';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom"
import Login from './components/Login';
import Layout from './components/AppLayout';
import { useState } from 'react';
import CountContext from './components/context';

const App = () => {
    const [count, setCount] = useState(0);

    return (
        <CountContext.Provider value={{ count, setCount }}>
            <Router>
                <Switch>
                    <Route exact path="/" component={Login} />
                    <Route render={props => {
                        if (localStorage.getItem('user') == null || localStorage.getItem('user') === "") {
                            return <Redirect to={{ pathname: '/' }} />
                        }

                        return <Layout />
                    }} />
                </Switch>
            </Router>
        </CountContext.Provider>
    );
}

export default App;
